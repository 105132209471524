const BaseUrl = "http://thequarter.co/admin/api/";
const Apis = {
    getCategories: BaseUrl + "get-all-categories",
    getHomeBanners: BaseUrl + "get-all-banners",
    getHomeProducts: BaseUrl + "get-products-by-parent-categories",
    getSingleProduct: BaseUrl + "get-single-product-sub-category",
    getProductsByCategory:BaseUrl + "get-products-by-cat",
    getMostVisited:BaseUrl + "get-most-visited",
    getFooter:BaseUrl + "get-footer",
    getSettings:BaseUrl + "settings",
    Contact :BaseUrl + "contact",
    SearchProduct:BaseUrl + "search-product",
    ParentCategories:BaseUrl + "get-parent-categories",
    ParentSearchCategories:BaseUrl + "get-parent-categories-search",
    CheckIfLocked: BaseUrl + "check-if-locked",
};
export default Apis;
