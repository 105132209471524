import {React, useState, useEffect} from "react";
import ProductsContainer from "../Components/Products/ProductsContainer";
import ProductCard from "../Components/Card/ProductCard";
import axios from "axios";
import Apis from "../Constants/Apis";
import Loading from "../Components/Layouts/Loading";
import {Container} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import ProductPageContainer from "../Components/Products/ProductPageContainer";
import styles from "../Components/Products/ProductsContainer.module.css";
import Pagination from "react-js-pagination";

const Search = () => {
    const [isLoading, setIsLoading] = useState();
    const [data, setdata] = useState();
    const location = useLocation();
    const category = new URLSearchParams(location.search).get("cat");
    const search = new URLSearchParams(location.search).get("search");
    const sort = new URLSearchParams(location.search).get("sortby") || "";
    const page = new URLSearchParams(location.search).get("page") || 1;
    const [totalItems, setTotalItems] = useState(0);
    const navigate = useNavigate();
    const handlePageChange = (pageNumber) => {
        navigate(`/search?cat=${category}&search=${search}&page=${pageNumber}`)
    }
    useEffect(() => {
        setdata();
        axios.post(Apis.SearchProduct + '?page=' + page, {
            category_slug: category,
            search: search,
            sortBy: sort
        }, {
            headers: {
                "Content-Type": "application/json",
            },
            dataType: "json",
        }).then(function (response) {
            if (response.data.status) {
                setdata(response.data.products);
                setTotalItems(response.data.total);
            }
        });
    }, [category, search, page, sort]);
    useEffect(() => {
            if (!data) {
                setIsLoading(true)
            } else {
                setIsLoading(false)
            }
        }
        , [data]);
    return <>
        <Loading show={isLoading}/>
        <Container>
            {data && data.length > 0 ?
                <ProductsContainer handlePageChange={handlePageChange} activePage={parseInt(page)}
                                   totalNum={totalItems}
                                   sortItem={sort}
                                   sort={(type) => {
                                       navigate(`/search?cat=${category}&search=${search}&page=${page}&sortby=${type}`)
                                   }}>
                    {data && data.map((product, index) => {
                        return <ProductCard data={product} key={index}/>
                    })}
                </ProductsContainer> :
                <div className={"no_data_container"}>
                    <div className={"no_data"}>No Product Found</div>
                </div>
            }
        </Container>
    </>
}
export default Search;