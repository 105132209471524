import {Row, Col} from 'react-bootstrap'
import classes from "./SiteMapBody.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSitemap} from "@fortawesome/free-solid-svg-icons";
import Title from "../Layouts/Title";
import {NavLink} from "react-router-dom";
import React from "react";

const SiteMapBody = (props) => {
    return (<Row className={classes.siteBody}>
            <Col lg={4} className={classes.sidecolumn}>
                <FontAwesomeIcon icon={faSitemap}/>
                <Title>
                    Site Map
                </Title>
            </Col>
            <Col lg={5} className='ml-5'>
                {(props.data).map((cat) => (
                    <div key={cat.slug}><a href={`/products?cat=${cat.slug}`} className={classes.catname}>{cat.name}</a>
                        <ul className={classes.catList}>
                            <li>
                                <ul className={classes.subCatList}>
                                    {cat.hasSubcategory && (cat.subcategory).map((subCat) => (
                                        <li key={subCat.slug}>
                                            <a href={`/products?cat=${subCat.slug}`}
                                               className={classes.subCatName}>{subCat.name}</a>
                                            <ul className={classes.shuSubList}>
                                                {subCat.hasSubcategory && (subCat.subcategory).map((subsubCat) => (
                                                    <li key={subsubCat.slug}><a href={`/products?cat=${subsubCat.slug}`}
                                                                                className={classes.subSubName}>{subsubCat.name}</a>
                                                    </li>))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </div>
                ))}
            </Col>
            <Col lg={3}>
                <div className={classes.screenlinks}>
                    <h5>All Screens</h5>

                    <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/about">About Us</NavLink></li>
                        <li><NavLink to="/contact">Contact Us</NavLink></li>
                        <li><NavLink to="/sitemap">Site Map</NavLink></li>
                        <li><NavLink to="/terms">Privacy policy</NavLink></li>
                        <li><NavLink to="/privacy">Terms & Conditions</NavLink></li>

                    </ul>
                </div>
            </Col>
        </Row>
    )
}
export default SiteMapBody;