import { Container } from "react-bootstrap";
import AboutBody from "../Components/About/AboutBody";
import HomeCarousel from "../Components/Home/Carousel";
import about1 from "../assets/images/about1.webp";
import about2 from "../assets/images/about2.webp";
import about3 from "../assets/images/about3.webp";
import Ceo from "../Components/About/Ceo";

const About = () => {
    const banners = [
        { "id": 1, "image": about1 },
        { "id": 2, "image": about2 },
        { "id": 3, "image": about3 }]
    return (<Container fluid="md">
        <HomeCarousel data={banners} />
        <AboutBody />
        <Ceo />
    </Container>)
}

export default About;