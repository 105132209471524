import classes from "./HomeProducts.module.css";
import {Row,} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import ProductCard from "../Card/ProductCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 1200},
        items: 6
    },
    desktop: {
        breakpoint: {max: 1200, min: 992},
        items: 5
    },
    tablet: {
        breakpoint: {max: 992, min: 576},
        items: 3
    },
    mobile: {
        breakpoint: {max: 576, min: 0},
        items: 2
    }
};

const HomeProducts = (props) => {
    return (<>
        {(props.data).map((cat,index) => (
            <div className={classes.products} key={index}>
                < div className={classes.splitBorder}>
                    {cat.name} - <NavLink to={`/products?cat=${cat.slug}`}> view all</NavLink>
                </div>
                <Carousel className={classes.multiCarousel} responsive={responsive} infinite={true}
                          autoPlay={true}
                          autoPlaySpeed={2000}>
                    {(cat.products).map((catProduct) => (
                        <ProductCard data={catProduct} key={catProduct.id}/>
                    ))}
                </Carousel>
            </div>))
        }
    </>)
}
export default HomeProducts;