import styles from "./QuikLinks.module.css"
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

const QuikLinks = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const showDropdown = () => {
        setShow(!show);
    }
    const hideDropdown = () => {
        setShow(false);
    };
    return (<div className={styles.nav}>
            <div onMouseEnter={showDropdown}
                 onMouseLeave={hideDropdown}>
                <div className={styles.title}>Quik Links</div>
                <div className={show ? styles.dropdownShow : styles.dropdownHidden}>
                    <div className={styles.element}
                         onClick={() => {
                             navigate('/')
                         }}>home
                    </div>
                    <div className={styles.element}
                         onClick={() => {
                             navigate('/about')
                         }}>About us
                    </div>
                    <div className={styles.element}
                         onClick={() => {
                             navigate('/contact')
                         }}>Contact us
                    </div>
                    <div className={styles.element}
                         onClick={() => {
                             navigate('/sitemap')
                         }}>Site map
                    </div>

                </div>
            </div>
        </div>
    )
}
export default QuikLinks;