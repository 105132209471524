import {Container} from "react-bootstrap";
import classes from "../Components/conditions & privacy/Terms.module.css"
import Title from "../Components/Layouts/Title";

const Terms = () => {
    return (<Container>
            <div className={classes.body}>
                <Title>Terms and Conditions</Title>
                <p>Please read these Terms and Conditions carefully before using the https://www.samikotob.com
                    e-commerce website portal operated by KotobCity .

                    Your access to and use of the Service is conditioned on your acceptance of and compliance with these
                    Terms. These Terms apply to all visitors, users and others who access or use the Service.

                    By accessing or using the Service you agree to be bound by these Terms. If you disagree with any
                    part of the terms then you may not access the Service.</p>
                <h6>Purchases</h6>

                <p>If you wish to purchase any product or service made available through the Service ("add to cart, checkout, pay online"), you may be asked to supply certain information relevant to your purchase including, without limitation, your full name ,email address, your contact info (including address, mobile, and phone number) in addition to other sub info such as social media accounts, etc..</p>
                <h6>Content</h6>

                <p>The products images/prices/features may vary due to unconditional errors in posting, and purchasing it does not implement any duties related to the seller side and may not be acknowledged as contract.</p>
                <h6>Payment Gateway</h6>

                <p>Billing Service/ Payment Gateway contains links to third-party web portal that are not owned or controlled by Sami Kotob Trading Est. or KotobCity CO

                    Sami Kotob Trading Est. has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Sami Kotob Trading Est. or KotobCity CO shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or payment gateway services.</p>
                <h6>Changes</h6>

                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 3 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>


            </div>
        </Container>

    )
}
export default Terms;