import {Container} from 'react-bootstrap';
import SiteMapBody from "../Components/sitemap/SiteMapBody";
import {useEffect, useState} from "react";
import axios from "axios";
import Apis from "../Constants/Apis";
import Loading from "../Components/Layouts/Loading";
const SiteMap = () => {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState()
    useEffect(() => {
        axios
            .get(Apis.getCategories, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setData(response.data.data);
                }
            });
    }, []);
    useEffect(() => {
            if (!data) {
                setIsLoading(true)
            } else {
                setIsLoading(false)
            }
        }
        , [data]);

    return <Container>
        <Loading show={isLoading}/>
        {data && <SiteMapBody data={data}/>}
    </Container>
}
export default SiteMap;