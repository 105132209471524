import HomeCarousel from "../Components/Home/Carousel";
import HomeProducts from "../Components/Home/HomeProducts";
import {Container} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import Apis from "../Constants/Apis";
import Loading from "../Components/Layouts/Loading";

const Home = () => {
    const [data, setdata] = useState();
    const [banners, setBanners] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        axios
            .post(Apis.getHomeBanners, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setBanners(response.data.data);
                }
            });
    }, []);
    useEffect(() => {
        axios
            .post(Apis.getHomeProducts, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setdata(response.data.data);
                }
            });
    }, []);

    useEffect(() => {
            if (!banners && !data) {
                setIsLoading(true)
            } else {
                setIsLoading(false)
            }
        }
        , [data, banners]);

    return <Container fluid="md">
        <Loading show={isLoading}/>
        {banners && <HomeCarousel data={banners}/>}
        {data && <HomeProducts data={data}/>}
    </Container>
}
export default Home;