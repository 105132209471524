import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import styles from "./CategoryButton.module.css";

const CategoryButton = (props) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const showDropdown = () => {
        setShow(!show);
    }
    const hideDropdown = () => {
        setShow(false);
    };

    return (
        <>
            {props.data.hasSubcategory ?
                <div className={styles.dropButton}>
                    <div onMouseEnter={showDropdown}
                         onMouseLeave={hideDropdown}>
                        <div className={styles.element}
                             onClick={() => {
                                 navigate(`/products?cat=${props.data.slug}`)
                             }}
                        >{props.data.name}
                        </div>
                        <div className={show ? styles.dropdownShow : styles.dropdownHidden}>
                            {props.data.hasSubcategory && props.data.subcategory.map((sub, index) => {
                                return (
                                    <div key={index} className={styles.element}
                                         onClick={() => {
                                             navigate(`/products?cat=${sub.slug}`)
                                         }}
                                    >{sub.name}</div>
                                )
                            })}
                        </div>
                    </div>
                </div> :
                <div className={styles.element}
                     onClick={() => {
                         navigate(`/products?cat=${props.data.slug}`)
                     }}>{props.data.name}</div>
            }
        </>)
}
export default CategoryButton;