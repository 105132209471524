import classes from "./Loading.module.css";
import loader from "../../assets/images/loader.gif"
import logo1 from "../../assets/images/logo1.png";
import {useEffect} from "react";

const Loading = (props) => {
    useEffect(() => {
        if (props.show) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "scroll";
        }
    }, [props.show])
    return (
        <>{
            props.show && <div className={classes.backdrop}>
                <img className={classes.logo} src={logo1} alt='logo'/>
                <img className={classes.spiner} src={loader} alt='loading...'/>
            </div>
        }</>)
}
export default Loading;