import ProductView from "../Components/Single product/ProductView";
import {Container} from "react-bootstrap";
import SingleCarousel from "../Components/Single product/singleCarousel";
import {useEffect, useState} from "react";
import axios from "axios";
import Apis from "../Constants/Apis";
import {useLocation} from "react-router-dom"
import Loading from "../Components/Layouts/Loading";
import {useNavigate} from "react-router-dom";
const SingleProduct = () => {
    const [data, setData] = useState();
    const [related, setRelated] = useState();
    const [isLoading,setIsLoading]=useState()
    const location = useLocation();
    const productCode = new URLSearchParams(location.search).get("code");
    const navigate=useNavigate();
    useEffect(() => {
        axios
            .post(Apis.getSingleProduct, {
                code: {productCode},
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setData(response.data.data);
                    setRelated(response.data.products);

                }
                if(!response.data.status){
                    navigate('/*')
                }
            });
    }, [productCode]);
    useEffect(() => {
            if (!data || !related) {
                setIsLoading(true)
            } else {
                setIsLoading(false)
            }
        }
        , [data, related]);
    return <Container>
        <Loading show={isLoading}/>
        {data && <ProductView data={data}/>}
        {related && <SingleCarousel data={related}/>}
    </Container>
}
export default SingleProduct;