import React, {useState} from "react";
import styles from "./Accordion.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from 'react-router-dom';
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

const Accordion = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [isSubActive, setIsSubActive] = useState(false);
    const navigate = useNavigate();
    return (<div className={styles.accordion}>
        <div className={styles.accordionItem}>
            <div
                className={
                    !isActive ? styles.accordionTitle : styles.accordionTitleHover
                }
            >
                <div
                    onClick={() => {
                        navigate(`/products?cat=${props.data.slug}`);
                        props.closeModal();
                    }}>{props.data.name}</div>
                <div
                    className={
                        !isActive
                            ? styles.opened
                            : styles.closed

                    }
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        onClick={() => {
                            setIsActive(!isActive);
                        }}
                    />
                </div>
            </div>
            <div
                className={
                    isActive ? styles.accordionContent : styles.accordionContentHidden
                }
            >

                {props.data.hasSubcategory && props.data.subcategory.map((sub, index) => {
                    return (
                        <div key={index}>
                            {!sub.hasSubcategory ? <div className={styles.elements}>{sub.name}</div> :
                                <div className={styles.elements}>
                                    <div className={styles.accordion}>
                                        <div className={styles.accordionItem}>
                                            <div
                                                className={
                                                    !isSubActive ? styles.accordionTitle : styles.accordionTitleHover
                                                }

                                            >
                                                <div onClick={() => {
                                                    navigate(`/products?cat=${sub.slug}`);
                                                    props.closeModal();}}>{sub.name}</div>
                                                <div
                                                    className={
                                                        !isSubActive
                                                            ? styles.opened
                                                            : styles.closed

                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                        onClick={() => {
                                                            setIsSubActive(!isSubActive);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    isSubActive ? styles.accordionContent : styles.accordionContentHidden
                                                }
                                            >
                                                {sub.hasSubcategory && sub.subcategory.map((element, index) => {
                                                    return <div key={index}
                                                                className={styles.elements}
                                                                onClick={() => {
                                                                    navigate(`/products?cat=${element.slug}`);
                                                                    props.closeModal();}}>{element.name}</div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    </div>)
}
export default Accordion;