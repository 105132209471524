import {Carousel} from "react-bootstrap";

const HomeCarousel = (props) => {
    return (
        <Carousel className='mb-5 'indicators={false}>
            {props.data && (props.data).map((banner) => (
                <Carousel.Item key={banner.id}>
                    <img
                        className="d-block w-100"
                        src={banner.image}
                        alt={`banner${banner.id}`}
                    />
                </Carousel.Item>
            ))}
        </Carousel>)
}
export default HomeCarousel;