import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import styles from "./CategoryNav.module.css";
import CategoryButton from "./CategoryButton";

const CategoryNav = (props) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const showDropdown = () => {
        if(props.data.subcategory.length === 0){
            setShow(false);
            return;
        }
        setShow(!show);
    }
    const hideDropdown = () => {
        setShow(false);
    }
    return (
        <div className={styles.nav}>
            <div onMouseEnter={showDropdown}
                 onMouseLeave={hideDropdown}>
                <div className={styles.title}
                     onClick={()=>{navigate(`/products?cat=${props.data.slug}`)}}
                >{props.data.name}
                </div>
                <div className={show ? styles.dropdownShow : styles.dropdownHidden}>
                    {props.data.hasSubcategory && props.data.subcategory.map((sub, index) => {
                        return(
                            <CategoryButton data={sub} key={index}/>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default CategoryNav;