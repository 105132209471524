import React from "react";
import styles from "./Categories.module.css";
import ProductsCollaps from "./ProductsCollaps";
import SmallProducts from "../Card/SmallProduct";

const Categories = (props) => {
    return (
        <div className={styles.categories}>
            <div className={styles.title}>Categories</div>
            {props.categories && props.categories.map((category, index) => {
                return <ProductsCollaps data={category} key={index}/>
            })}
            <div className={styles.title}>Most Visited</div>
            {props.visited && props.visited.map((p,index)=>{
                return <SmallProducts data={p} key={index}/>
            })}

        </div>
    )
}
export default Categories;