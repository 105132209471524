import Title from "../Layouts/Title";
import classes from './AboutBody.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Row, Col} from 'react-bootstrap'
import {faEye, faBullseye} from "@fortawesome/free-solid-svg-icons";
import icon from "../../assets/images/icon.png";


const AboutBody = () => {
    return (<div className={classes.body}>
        <Title>Our History</Title>
        <div>
            <p>The Quarter is a Tripoli-based multinational home and commercial appliances and electronics company with a reputation for being the top home appliance sellers, distributors, and importers. Our family-owned business has long been at the forefront of providing Lebanon with top-of-the-line household appliances and electronics. Many of our things can still be seen in high-end homes, including some of Lebanon's most prestigious residences.</p>
            <p>Our current focus is on providing dedicated after-sales service in a welcoming environment at reasonable prices and the greatest home appliance and electronic solutions available. So, while the scope of our product offering has grown over time, our core values have remained the same a passion and commitment to providing high-quality home appliance solutions with unsurpassed customer care. As a result, we remain the go-to place for unique and memorable household appliances and electronics.</p>
        </div>

        <Row className='my-5 text-center justify-content-md-center'>
            <Col xs={12} md>
                <div className={classes.mission}>
                    <div><FontAwesomeIcon icon={faBullseye}/>
                        <h4>Our Mission</h4>
                        <p className={classes.description}>Our objective is to be the top technology provider, assisting consumers in finding, discovering, and purchasing anything they want, as well as empowering people's lives through innovative electronic devices.</p>
                    </div>
                </div>
            </Col>
            <Col xs={12} md>
                <div className={classes.vision}>
                    <div><FontAwesomeIcon icon={faEye}/>
                        <h4>Our Vision</h4>
                        <p className={classes.description}>As the indisputable leading provider - most dependable, competitive, and adaptable - we want to generate ultimate value for our customers by providing sophisticated goods and integrity service solutions.</p>
                    </div>
                </div>
            </Col>
            <Col xs={12} md>
                <div className={classes.vision}>
                    <div>
                        <img src={icon} alt={icon} className={classes.visionimage}/>
                        <h4>Our Values</h4>
                        <p className={classes.description}>You will find us attentive, proactive, and extremely engaged with our customers. We move with our customers into long-term sustainable relationships since technology is a fast-moving phenomenon.</p>
                    </div>
                </div>
            </Col>
        </Row>
    </div>)
}
export default AboutBody;