import React, {useState} from "react";
import styles from "./ProductsContainer.module.css";
import {useNavigate} from "react-router-dom";
import Pagination from "react-js-pagination";

const ProductsContainer = (props) => {
    const navigate = useNavigate();
    return (
        <div className={styles.maincontainer}>
            <div className={styles.flex}>
                <div className={styles.bread}>
                    {props.breadcrumbs && props.breadcrumbs.map((bread, index) => {
                        return <div className={styles.categoryTitle} key={index}
                                    onClick={() => {
                                        navigate(`/products?cat=${bread.slug}`)
                                    }}>{bread.name} {index !== 0 &&
                        <span>-></span>}</div>
                    }).reverse()}
                </div>
                <div className={styles.selectContainer}>
                    <select className={styles.sort} value={props.sortItem} onChange={(event) => {
                        props.sort(event.target.value);
                    }}>
                        <option value={""}>Default</option>
                        <option value={"a_to_z"}>Name (A To Z)</option>
                        <option value={"z_to_a"}>Name (Z To A)</option>
                        <option value={"low_to_high"}>Price (Low To High)</option>
                        <option value={"high_to_low"}>Price (High To Low)</option>
                    </select>
                </div>
            </div>
            <div className={styles.container}>
                {props.children}
            </div>
            <div className={styles.paginate}>
                {props.totalNum !== 0 && <Pagination
                    activePage={props.activePage}
                    itemsCountPerPage={20}
                    totalItemsCount={props.totalNum}
                    pageRangeDisplayed={5}
                    activeClass={styles.activeClass}
                    onChange={(pageNumber) => {

                        props.handlePageChange(pageNumber)
                    }}
                />}
            </div>

        </div>)
}
export default ProductsContainer;