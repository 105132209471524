import React, { useEffect, useState } from "react";
import ProductPageContainer from "../Components/Products/ProductPageContainer";
import ProductsContainer from "../Components/Products/ProductsContainer";
import Categories from "../Components/Products/Categories";
import ProductCard from "../Components/Card/ProductCard";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Apis from "../Constants/Apis";
import Loading from "../Components/Layouts/Loading";
import { useNavigate } from "react-router-dom";
import notfound from "../assets/images/notfound.png";

const Products = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const category = new URLSearchParams(location.search).get("cat");
    const page = new URLSearchParams(location.search).get("page") || 1;
    const sort = new URLSearchParams(location.search).get("sortby") || "";
    const [categories, setcategories] = useState();
    const [data, setdata] = useState();
    const [breadcrumbs, setbreadcrumbs] = useState();
    const [mostVisted, setMostVisited] = useState();
    const [isLoading, setIsLoading] = useState();
    const [totalItems, setTotalItems] = useState(0)

    const handlePageChange = (pageNumber) => {
        navigate(`/products?cat=${category}&page=${pageNumber}`)
    }
    useEffect(() => {
        axios
            .get(Apis.getCategories, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setcategories(response.data.data);
                }
            });
        axios
            .post(Apis.getMostVisited, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setMostVisited(response.data.products);
                }
            });

    }, []);
    useEffect(() => {
        setdata();
        setbreadcrumbs();
        setTotalItems(0);
        axios
            .post(Apis.getProductsByCategory + `?page=${page}`, {
                slug: category,
                sortBy: sort
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setdata(response.data.products);
                    setbreadcrumbs(response.data.breadcrumbs);
                    setTotalItems(response.data.total);
                }else{
                    setdata([])
                    setbreadcrumbs([]);
                    setTotalItems(0);
                }
            });
    }, [category, page, sort]);

    useEffect(() => {
        if (!categories || !data || !breadcrumbs) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    }
        , [data, categories, breadcrumbs]);

    return (
        <>
            <Loading show={isLoading} />
            <ProductPageContainer>
                <Categories categories={categories} visited={mostVisted} />
                {data && data.length > 0 ?
                    <ProductsContainer breadcrumbs={breadcrumbs} handlePageChange={handlePageChange}
                        activePage={parseInt(page)} totalNum={totalItems}
                        sortItem={sort}
                        sort={(type) => {
                            navigate(`/products?cat=${category}&page=${page}&sortby=${type}`)
                        }}>
                        {data.map((product, index) => {
                            return <ProductCard data={product} key={index} />
                        })}
                    </ProductsContainer> :
                    <div className={"no_data_container"}>
                        <div className={"no_data"}>No Product Found <img src={notfound} alt={notfound} /></div>
                    </div>
                }
            </ProductPageContainer>
        </>)
}
export default Products;