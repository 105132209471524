import './App.css';
import { Routes, Route } from "react-router-dom"
import Home from './Screens/Home';
import About from './Screens/About';
import Contact from './Screens/Contact';
import Products from './Screens/Products';
import SiteMap from './Screens/SiteMap';
import SingleProduct from './Screens/SingleProduct';
import Search from './Screens/Search';
import Header from './Components/Layouts/Header';
import Footer from "./Components/Layouts/Footer";
import Scroll from "./Components/Utilities/Scroll"
import WhatsappButton from "./Components/Layouts/WhatsappButton";
import { useEffect, useState } from "react";
import axios from "axios";
import Apis from "./Constants/Apis";
import NotFound from "./Screens/NotFound";
import Terms from "./Screens/Terms";
import Privacy from "./Screens/Privacy";

function App() {
    const [loading, setloading] = useState(true);
    useEffect(() => {
        axios
            .post(Apis.getSettings, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    const settings = localStorage.getItem("settings");
                    if (!settings) {
                        localStorage.setItem("settings", JSON.stringify(response.data.data));
                        window.location.reload();
                    }
                    setloading(false);

                }
            });
    }, []);

    return (<>
        {!loading && <div className="App">
            <Header />
            <Scroll />
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/about' element={<About />} />
                <Route exact path='/contact' element={<Contact />} />
                <Route exact path='/products' element={<Products />} />
                <Route exact path='/sitemap' element={<SiteMap />} />
                <Route exact path='/product' element={<SingleProduct />} />
                <Route exact path='/search' element={<Search />} />
                <Route exact path='/terms' element={<Terms />} />
                <Route exact path='/privacy' element={<Privacy />} />
                <Route exact path='/*' element={<NotFound />} />
            </Routes>
            <WhatsappButton />
            <Footer />
        </div>}
    </>
    );
}

export default App;
