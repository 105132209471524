import {Row, Col} from "react-bootstrap";
import classes from "./ProductView.module.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import React, {useEffect, useState} from "react";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import DOMPurify from "dompurify";
/* eslint-disable no-useless-escape */
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 992},
        items: 5
    },
    tablet: {
        breakpoint: {max: 992, min: 576},
        items: 6
    },
    mobile: {
        breakpoint: {max: 576, min: 0},
        items: 4
    }
};
const ProductView = (props) => {
    const [image, setImage] = useState();
    const [isDesc, setIsDesc] = useState(true)
    const [copied, setCopied] = useState(false);
    // const {code} = useParams();
    useEffect(() => {
        setImage(props.data.images[0].image);
    }, [props.data])
    const copy = () => {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(true);
    }
    const myDescription = DOMPurify.sanitize(props.data.description);
    const mySpecification = DOMPurify.sanitize(props.data.specification);
    const settings =JSON.parse(localStorage.getItem("settings"));
    return (<Row className={classes.mainBox}>
        <Col md={12} lg={6} className='mb-2'>
            <div className={classes.productImage}>
                {/* {(props.data.hasDiscount) && <div className={classes.discount}>
                    {props.data.discount} %</div>} */}
                <InnerImageZoom src={`${image}`} zoomSrc={`${image}`} zoomType='hover' hideCloseButton={true}/>
            </div>
            <div className={classes.allImages}>
                <Carousel responsive={responsive} arrows={false} infinite={true} autoPlay={true} autoPlaySpeed={1500}>
                    {(props.data.images).map((image) => (
                        <img src={image.image}
                             onClick={() => {
                                 setImage(image.image)
                             }}
                             key={image.id}
                             alt={image.id}/>
                    ))}
                </Carousel>
            </div>
        </Col>
        <Col md={12} lg={6} className={classes.productDetails}>
            <h2 className="mb-5 ">{props.data.name}</h2>
            <Row>
                <Col xs={8}>
                    <p className={classes.title}>BRAND : <span className={classes.brand}>{props.data.brand.name}</span>
                    </p>
                    <p className={classes.title}>CODE : <span className={classes.code}>{props.data.code}</span></p>
                    {(props.data.hasDiscount) && <p className={classes.discount}>
                       SALE : {props.data.discount}%</p>}
                    <p className={classes.title}>PRICE : {(props.data.hasDiscount) &&
                    <span className={classes.oldPrice}>${props.data.old_price}</span>}
                        <span
                            className={classes.price}>${props.data.new_price}</span></p>
                    <p className={classes.breadcrumbs}>
                        {(props.data.breadcrumbs).map((cat) => (
                            <a href={`/products?cat=${cat.slug}`} key={cat.slug}> {cat.name} </a>)).reverse()}
                    </p>
                    <a target="_blank" rel="noreferrer" href={`https://wa.me/${settings.whatsApp}?text=${window.location.href}`}
                       className={classes.watsBtn}>
                        <FontAwesomeIcon icon={faWhatsapp}/> Get more details
                    </a>
                    <span className={classes.copyBtn} onClick={copy}><FontAwesomeIcon
                        icon={faCopy}/>{!copied ? " Copy link" : " Copied!"}</span>
                </Col>
                <Col xs={4}>
                    <img className={classes.brandImage}
                         src={props.data.brand.image}
                         alt='brand'/>
                </Col>
            </Row>
        </Col>
        <Row className='mt-5'>
            <Col xs={12}>
                <p className={classes.toggleBar}>
                <span className={isDesc ? `${classes.toggleBtn} ${classes.active}` : classes.toggleBtn}
                      onClick={() => {
                          setIsDesc(true)
                      }}
                >Description</span>
                    <span className={!isDesc ? `${classes.toggleBtn} ${classes.active}` : classes.toggleBtn}
                          onClick={() => {
                              setIsDesc(false)
                          }}
                    >Specification</span>
                </p>
                {isDesc ? <div className={classes.desc} dangerouslySetInnerHTML={{ __html: myDescription }} /> :
                    <div className={classes.desc} dangerouslySetInnerHTML={{ __html: mySpecification }} /> }
            </Col>
        </Row>

    </Row>)
}
export default ProductView;