import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import classes from "../Home/HomeProducts.module.css";
import ProductCard from "../Card/ProductCard";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 1200},
        items: 6
    },
    desktop: {
        breakpoint: {max: 1200, min: 992},
        items: 5
    },
    tablet: {
        breakpoint: {max: 992, min: 576},
        items: 3
    },
    mobile: {
        breakpoint: {max: 576, min: 0},
        items: 2
    }
};
const SingleCarousel=(props)=>{
    return(<>
        <div className={classes.splitBorder}>
            RELATED PRODUCTS
        </div>
        <Carousel className={classes.multiCarousel} responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000}>
            {(props.data).map((product) => (
                <ProductCard data={product} key={product.id}/>
            ))}
        </Carousel>
        </>)
}
export  default SingleCarousel;