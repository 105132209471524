import React from "react";
import styles from "./SmallProducts.module.css"
import {useNavigate} from "react-router-dom";

const SmallProducts = (props) => {
    const navigate=useNavigate();
    return (<div className={styles.small} onClick={()=>{
        navigate(`/product?code=${props.data.code}`)
    }}>
        <div>
            <img src={props.data.mainImg} alt="img"/>
        </div>
        <div className={styles.details}>
            <div>{(props.data.name).substring(0, 50)}{props.data.name.length >=  50 ? '...' : '' }</div>
            <p className={styles.prices}>
                {props.data.hasDiscount && <span className={styles.oldprice}>${props.data.old_price}</span>}
                <span>${props.data.new_price}</span>
            </p>
        </div>
    </div>)
}
export default SmallProducts;