import React, { useEffect, useState } from "react";
import styles from "./Footer.module.css";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/images/blacklogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import axios from "axios";
import Apis from "../../Constants/Apis";

const Footer = () => {
    const [data, setdata] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(Apis.getFooter, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setdata(response.data.data);
                }
            });
    }, []);
    const settings = JSON.parse(localStorage.getItem("settings"));
    return (
        <>
            <div className={styles.footerContainer}>
                <Container className={styles.footer}>
                    <Row>
                        <Col md={3} className={styles.section}>
                            <img src={logo} alt={"logo"} onClick={() => {
                                navigate(`/`)
                            }} />
                            <p className={styles.description}>
                                {settings && settings.description}
                            </p>
                        </Col>
                        <Col md={1}>

                        </Col>
                        <Col md={4} sm={12} xs={12} className={styles.section}>
                            <Row>
                                <Col md={6} sm={6} xs={6}>
                                    <div className={styles.heading}>
                                        Quarter Pages
                                    </div>
                                    <div className={styles.links}>
                                        <NavLink to="/">Home</NavLink>
                                        <NavLink to="/about">About Us</NavLink>
                                        <NavLink to="/contact">Contact Us</NavLink>
                                        <NavLink to="/sitemap">Site Map</NavLink>
                                        <NavLink to="/terms">Privacy policy</NavLink>
                                        <NavLink to="/privacy">Terms & Conditions</NavLink>

                                    </div>
                                </Col>
                                <Col md={6} sm={6} xs={6}>
                                    <div className={styles.heading}>
                                        Main Categories
                                    </div>
                                    <Col md={12} className={styles.links}>
                                        {data && data.map((link, index) => {
                                            return <NavLink to={`/products?cat=${link.slug}`}
                                                key={index}>{link.name}</NavLink>
                                        })}
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <div className={styles.heading}>
                                Where To Find Us
                            </div>
                            <div className={styles.socialmedia}>
                                <p><span className={styles.title}><span className={styles.red}><FontAwesomeIcon
                                    icon={faHome} /></span> Address:</span> {settings && settings.address}</p>
                                <p><span className={styles.title}><span className={styles.red}><FontAwesomeIcon
                                    icon={faPhone} /></span> Phone:</span> <a className={styles.socialLink}
                                        href={`tel:${settings.phone}`}>{settings && settings.phone}</a>
                                </p>
                                <p><span className={styles.title}><span className={styles.red}><FontAwesomeIcon
                                    icon={faEnvelope} /> </span>Email:</span><a className={styles.socialLink}
                                        href={`mailto:${settings.email}`}> {settings && settings.email}</a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={styles.subfooterColor}>
                <Container className={styles.subfooter}>
                    <div className={styles.poweredby}>
                        Developed by Creative Vision &#169;
                    </div>
                    <div className={styles.socialIcons}>
                        <a href={settings.facebook} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                        {settings.twitter &&
                            <a href={`${settings.twitter}`} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>}
                        <a href={settings.instagram} target="_blank" rel="noreferrer" className={styles.insta}><FontAwesomeIcon
                            icon={faInstagram} /></a>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default Footer;