import React from "react";
import styles from "./ProductPageContainer.module.css";
import {Container} from "react-bootstrap";

const ProductPageContainer = (props) => {
    return (
        <Container className={styles.cont}>
            {props.children}
        </Container>);
}
export default ProductPageContainer;