import React, {useState} from "react";
import styles from "./ProductsCollaps.module.css";
import {useNavigate} from "react-router-dom";
import SubCollaps from "./SubCollaps";

const ProductsCollaps = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [close,setclose]=useState(false)
    const navigate = useNavigate();
    return <div className={styles.accordion}>
        <div className={styles.accordionItem}>
            <div
                className={
                    !isActive ? styles.accordionTitle : styles.accordionTitleHover
                }
            >
                <div onClick={() => {
                    navigate(`/products?cat=${props.data.slug}`);
                }}>{props.data.name}</div>
                <div>
                    {!isActive ? <span className={styles.plus} onClick={() => {
                            setIsActive(!isActive);
                            setclose(false);
                        }}>+</span> :
                        <span
                            className={styles.plus}
                            onClick={() => {
                            setIsActive(!isActive);
                            setclose(true);
                        }}>-</span>}
                </div>
            </div>
            <div
                className={
                    isActive ? styles.accordionContent : styles.accordionContentHidden
                }
            >
                <div className={styles.elements}>
                    {
                        props.data.hasSubcategory && props.data.subcategory.map((sub, index) => {
                            return <div key={index}>
                                {sub.hasSubcategory ?
                                    <SubCollaps data={sub} close={close}/>
                                    : <div className={styles.elements} key={index} onClick={() => {
                                        navigate(`/products?cat=${sub.slug}`)
                                    }}>{sub.name}</div>
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    </div>
}
export default ProductsCollaps;