import classes from "../Components/Layouts/Privacy.module.css"
import Title from "../Components/Layouts/Title";
import {Container} from "react-bootstrap";

const Privacy = () => {
    return (<Container>
            <div className={classes.body}>
                <Title>Privacy Info</Title>
                <ul>
                    <li>For online transactions and upon check out, we collect personal information including
                        contact, billing, and shipping information.
                    </li>
                    <li>Kotob City does not sell personal information to third parties.</li>
                    <li>We might use the information you provide to send you marketing communications.</li>
                    <li>You may choose to stop receiving e-mail, direct mail and text messages by contacting us.
                    </li>
                    <li>You may choose to update and correct your personal information by signing in.</li>
                </ul>
                <h6>How to Contact Us
                </h6>
                <p><span>Phone : </span>+961 06 412 413</p>
                <p><span>E-mai : </span>info@thequarter.co</p>
            </div>
        </Container>
    )
}
export default Privacy;