import {Container, Col, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import classes from "../Components/Layouts/NotFound.module.css"
import notfound from "../assets/images/notfound.webp"

const NotFound = () => {

    return (<Container className={classes.containerStyle}>
        <Row>
            <Col md={3}>
                <img src={notfound} alt='notFound' style={{width:'100%'}}/>
            </Col>
            <Col md={9} className='ps-5'>
                <h1 >404 NOT FOUND</h1>
                <p>We can't find the page you're looking for !</p>
                <NavLink to="/">go to home</NavLink>
            </Col>
        </Row>

    </Container>)
}
export default NotFound;