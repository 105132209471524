import {Row, Col} from 'react-bootstrap';
import Title from "../Layouts/Title";
import Swal from 'sweetalert2';
import classes from './ContactForm.module.css'
import ContactMap from "./ContactMap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faUser, faMessage, faEnvelope, faHome, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faInstagram, faTwitter, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import React, {useRef, useState} from "react";
import axios from "axios";
import Apis from "../../Constants/Apis";
/* eslint-disable no-useless-escape */
const ContactForm = () => {
    const [nameIsValid, setNameIsValid] = useState(false);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [msgIsValid, setMsgIsValid] = useState(false);
    const [nameIsTouched, setNameIsTouched] = useState(false)
    const [emailIsTouched, setEmailIsTouched] = useState(false)
    const [messageIsTouched, setMessageIsTouched] = useState(false)

    const nameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();
    const nameValidation = () => {
        const name = nameRef.current.value;
        setNameIsTouched(true)
        if (name.trim() === "") {
            setNameIsValid(false)
        } else {
            setNameIsValid(true)
        }
    }
    const emailValidation = () => {
        const email = emailRef.current.value;
        setEmailIsTouched(true)
        if ((email.trim() === '') || (!/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(email))) {
            setEmailIsValid(false)
        } else {
            setEmailIsValid(true)
        }
    }
    const msgValidation = () => {
        const message = messageRef.current.value;
        setMessageIsTouched(true)
        if (message.trim() === "") {
            setMsgIsValid(false)
        } else {
            setMsgIsValid(true)
        }
    }
    const submitHandler = (event) => {
        event.preventDefault();
        nameValidation();
        emailValidation();
        msgValidation();
        const formIsValid = nameIsValid && emailIsValid && msgIsValid;
        if (formIsValid) {
            axios
                .post(Apis.Contact, {
                    name: nameRef.current.value,
                    email: emailRef.current.value,
                    description: messageRef.current.value
                }, {
                    headers: {
                        "Content-Type": "application/json",
                    },

                    dataType: "json",
                })
                .then(function (response) {
                    if (response.data.status) {
                        nameRef.current.value = "";
                        emailRef.current.value = "";
                        messageRef.current.value = "";
                        setNameIsTouched(false);
                        setEmailIsTouched(false);
                        setMessageIsTouched(false);
                        Swal.fire({
                            position: 'top-end',
                            title: 'Your message was sent successfully',
                            showConfirmButton: false,
                            color: "green",
                            timer: 2000,
                        })
                    }
                });
        } else {
            return;
        }
    }
    const settings = JSON.parse(localStorage.getItem("settings"));
    return (<div className={classes.contact}>
        <Row>
            <Title>Contact Us</Title>
            <Col xs={12} md>
                <form onSubmit={submitHandler}>
                    <label htmlFor='name'><FontAwesomeIcon icon={faUser}/> Full name : </label>
                    {(!nameIsValid && nameIsTouched) &&
                    <span className={classes.error}> Name field is not valid !</span>}
                    <input
                        className={(!nameIsValid && nameIsTouched) ? classes.invalid : ''}
                        placeholder='Type here ...'
                        type='text'
                        id='name'
                        ref={nameRef}
                        onBlur={nameValidation}
                        onChange={() => {
                            setNameIsValid(true)
                        }}
                    />
                    <label htmlFor='email'><FontAwesomeIcon icon={faEnvelope}/> E-mail address : </label>
                    {(!emailIsValid && emailIsTouched) &&
                    <span className={classes.error}> E-mail Address is not valid !</span>}
                    <input
                        className={(!emailIsValid && emailIsTouched) ? classes.invalid : ''}
                        placeholder='Type here ...'
                        type='email'
                        id='email'
                        ref={emailRef}
                        onBlur={emailValidation}
                        onChange={() => {
                            setEmailIsValid(true)
                        }}
                    />
                    <label htmlFor='text'><FontAwesomeIcon icon={faMessage}/> Ask us here : </label>
                    {(!msgIsValid && messageIsTouched) &&
                    <span className={classes.error}> Textarea is not valid !</span>}
                    <textarea
                        className={(!msgIsValid && messageIsTouched) ? classes.invalid : ''}
                        placeholder='Type here ...'
                        rows={5}
                        id='text'
                        ref={messageRef}
                        onBlur={msgValidation}
                        onChange={() => {
                            setMsgIsValid(true)
                        }}
                    ></textarea>
                    <button><FontAwesomeIcon icon={faPaperPlane}/> SEND</button>
                </form>
                <div className={classes.info}>
                    <p className={classes.formHead}>Can we help you with anything else today?</p>
                    <p><span className={classes.title}><FontAwesomeIcon
                        icon={faEnvelope}/> Email :</span><a href={`mailto:${settings.email}`}> {settings.email}</a>
                    </p>
                    <p><span className={classes.title}><FontAwesomeIcon
                        icon={faHome}/> Address :</span> {settings.address}</p>
                    <p><span className={classes.title}><FontAwesomeIcon
                        icon={faPhone}/> Phone :</span> <a href={`tel:${settings.phone}`}>{settings.phone}</a></p>
                    <p className={classes.socialMedia}>
                        <a href={settings.facebook} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookSquare}/></a>
                        <a href={settings.instagram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                        {settings.twitter &&
                        <a href={`${settings.twitter}`} target="_blank"  rel="noreferrer"><FontAwesomeIcon icon={faTwitter}/></a>}
                        <a href={`https://api.whatsapp.com/send?phone=${settings.whatsApp}`}
                           target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp}/></a>
                    </p>
                </div>
            </Col>
            <Col xs={12} md>
                <ContactMap/>
            </Col>
        </Row>
    </div>)
}
export default ContactForm;