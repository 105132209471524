import React from 'react';
import styles from "./Ceo.module.css";
import { Container } from "react-bootstrap";
import about1 from "../../assets/images/ceo.webp";


const Ceo = props => {
    return <Container>
        <div className={styles.flex}>
            <div className={styles.section}>
                <img src={about1} alt={about1} />
            </div>
            <div className={styles.section1}>
                <div className={styles.title}>CEO Words</div>
                <div className={styles.description}>
                    Al Mountazah trading company is a leading distributor of premium brands in Lebanon.
                    Headquartered in Beirut and tripoli, Al Mountazah trading co. provides comprehensive distribution solutions supported by a solid infrastructure, a prerequisite for effective market coverage.
                    Our Company handles a large product portfolio covering the following categories:Electronics, home appliances, kitchenwares, furnitures and carpets.
                    Al Mountazah celebrated in 2022 the milestone of 40  years of continual service to its customers. Operating in Lebanon.
                    Father: Our Mission is to add value by enhancing demand, ensuring availability of goods, efficient logistics and dedicated customer service.
                    Father: Firmly rooted in Lebanon, we take pride in our people, in the principals we represent, and in the privileged relationship with our customers.
                </div>
                <div className={styles.signature}>
                    Sobhi Bakkar
                </div>
            </div>
        </div>
    </Container>
}
export default Ceo;