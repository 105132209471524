import classes from "./ProductCard.module.css";
import { NavLink } from "react-router-dom";
const ProductCard = (props) => {

    return (<div className={classes.card}>
        <NavLink to={`/product?code=${props.data.code}`} aria-hidden="true">
            <div className={classes.cardImage}>
                <img
                    src={props.data.mainImg}
                    alt='product' />
            </div>
            <div className={classes.cardbody}>
                <h6>{(props.data.name).substring(0, 50)}{props.data.name.length >=  50 ? '...' : '' }</h6>
                <div className={classes.flex}>
                    {(props.data.hasDiscount) && <div className={classes.discount}>Sale {props.data.discount}%</div>}
                </div>
                <p className={classes.prices}>
                    {(props.data.hasDiscount) && <span className={classes.oldprice}>${props.data.old_price}</span>}
                    <span className={classes.price}>${props.data.new_price}</span>
                </p>
            </div>
        </NavLink>
    </div>
    )
}
export default ProductCard;