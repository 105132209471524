import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";
import blackLogo from "../../assets/images/blacklogo.png"
// import logo1 from "../../assets/images/logo1.png"
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav } from "react-bootstrap";
import CategoryNav from "./CategoryNav";
import axios from "axios";
import Apis from "../../Constants/Apis";
import Accordion from "./Accordion";
import Select from "react-select";
import QuikLinks from "./QuikLinks";
/* eslint-disable no-useless-escape */
const customStyles = {
    control: base => ({
        ...base,
        border: "none",
        color: "black",
        backgroundColor: "#eee",
        "&:hover": {
            border: "none",
            outline: "none"
        },
        textAlign: "start",
        textTransform: "capitalize",
        cursor: "pointer",
        boxShadow: 'none',
        width: 200,
        borderRadius: 0,
        zIndex: "999999999999"
        // fontSize:"12px"
    }),
    option: (styles, state) => ({
        ...styles,
        color: "black",
        backgroundColor: state.isSelected ? 'rgb(178, 0, 0)' : 'white',
        "&:hover": {
            backgroundColor: "rgba(178, 0, 0,0.5)",
        },
        cursor: 'pointer',
        textAlign: "start",
        textTransform: "capitalize",
        zIndex: "999999999999"
    })
};
const Header = () => {
    const navigate = useNavigate();
    const [data, setdata] = useState();
    const [Modal, setModal] = useState(false);
    const [categories, setcategories] = useState([]);
    const [inputValue, setinputValue] = useState("");
    const [select, setselect] = useState();
    useEffect(() => {
        axios
            .get(Apis.getCategories, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setdata(response.data.data);
                }
            });
        axios
            .get(Apis.ParentSearchCategories, {
                headers: {
                    "Content-Type": "application/json",
                },
                dataType: "json",
            })
            .then(function (response) {
                if (response.data.status) {
                    setcategories(response.data.data);
                }
            });
    }, []);

    useEffect(() => {
        if (Modal) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "scroll";
        }
    }, [Modal])
    const settings = JSON.parse(localStorage.getItem("settings"));
    const search = () => {
        if (inputValue === "") {
            return;
        }
        if (select) {
            navigate(`/search?cat=${select.value}&search=${inputValue}`)
            setinputValue("");
        } else {
            navigate(`/search?cat=all&search=${inputValue}`)
            setinputValue("");

        }
    }
    return (
        <div>
            <div className={styles.firstBackgound}>
                <Container className={styles.first}>
                    <a href={`tel:${settings.phone}`}><FontAwesomeIcon icon={faPhone} /> {settings.phone}</a>
                    <a href={`mailto:${settings.email}`}><FontAwesomeIcon
                        icon={faEnvelope} /> {settings.email}</a>
                </Container>
            </div>
            <div className={styles.mainBackground}>
                <Container className={styles.main}>
                    <div onClick={() => {
                        navigate("/");
                    }}>
                        <img src={blackLogo} alt="logo" className={styles.img} />
                    </div>
                    <div className={styles.search}>
                        <Select styles={customStyles}
                            defaultValue={categories[0]}
                            onChange={setselect}
                            options={categories}
                            components={{ IndicatorSeparator: () => null, LoadingIndicator: null, ClearIndicator: null }}
                            className={styles.selectindicator}
                            placeholder='All' />
                        <input type={"text"} value={inputValue} onChange={(element) => {
                            setinputValue(element.target.value)
                        }} onKeyUp={(event) => {
                            if (event.keyCode === 13) {
                                search();
                            }
                        }} />
                        <FontAwesomeIcon icon={faSearch} className={styles.icon} onClick={() => {
                            search();
                        }} />
                    </div>
                </Container>
            </div>
            <div className={styles.subheaderBackground}>
                <Navbar bg="secondary" expand="md" variant="dark" className={"p-0"}>
                    <Container>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                {data && data.map((category, index) => {
                                    return (
                                        <CategoryNav data={category} key={index} />
                                    )
                                })}
                                <QuikLinks />
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container>
                    <div className={styles.bars} onClick={() => {
                        setModal(true);
                    }}><FontAwesomeIcon icon={faBars} /> MENU
                    </div>
                </Container>
            </div>
            <div className={Modal ? styles.sidebarOpen : styles.sidebarClosed}>
                <div className={styles.sideheading}>
                    <img src={blackLogo} alt="logo" className={styles.sideimg} />
                    <div className={styles.cancelBtn}
                        onClick={() => {
                            setModal(false);
                        }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                {data && data.map((category, index) => {
                    return (
                        <Accordion data={category} key={index} closeModal={() => {
                            setModal(false);
                        }} />
                    )
                })}
            </div>
        </div>
    )

}
export default Header;