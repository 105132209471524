import React, {useState,useEffect} from "react";
import styles from "./SubCollaps.module.css";
import {useNavigate} from "react-router-dom";

const SubCollaps = (props) => {
    const [isActive, setIsActive] = useState(false);
    const navigate=useNavigate();
    useEffect(()=>{
        if(props.close){
            setIsActive(false);
        }
    },[props.close])
    return <div className={styles.accordion}>
        <div className={styles.accordionItem}>
            <div
                className={
                    !isActive ? styles.accordionTitle : styles.accordionTitleHover
                }
            >
                <div onClick={()=>{
                    navigate(`/products?cat=${props.data.slug}&page=1`);
                }}>{props.data.name}</div>
                <div>
                    {!isActive ? <span onClick={() => {
                            setIsActive(!isActive);
                        }}>+</span> :
                        <span onClick={() => {
                            setIsActive(!isActive);
                        }}>-</span>}
                </div>
            </div>
            <div
                className={
                    isActive ? styles.accordionContent : styles.accordionContentHidden
                }
            >
                <div className={styles.elements}>
                    {
                        props.data.hasSubcategory && props.data.subcategory.map((sub, index) => {
                            return <div className={styles.elements} key={index} onClick={()=>{ navigate(`/products?cat=${sub.slug}&page=1`)}}>{sub.name}</div>
                        })
                    }
                </div>
            </div>
        </div>
    </div>
}
export default SubCollaps;