import classes from './ContactForm.module.css'
const ContactMap=()=>{
    const settings =JSON.parse(localStorage.getItem("settings"));
    return <div className={classes.mapSection}>
    <iframe
        title='map'
        src={settings.location}
        width="100%" height="100%"  allowFullScreen="" loading="lazy" style={{border:'none'}}></iframe>
    </div>
}
export  default ContactMap;