import React from "react";
import styles from "./WhatsappButton.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
const settings =JSON.parse(localStorage.getItem("settings"));
const WhatsappButton=()=>{
    return(
        <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=${settings.whatsApp}`} className={styles.btn}>
            <FontAwesomeIcon icon={faWhatsapp}/>
        </a>
    )
}
export default WhatsappButton;